import * as React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '0',
        borderWidth: '2px',
        "&:hover": {
            borderWidth: "2px"
        },
    },
}));

function GenericButton({children, onClick}) {
    const classes = useStyles();
    return (
        <Button className={classes.root} variant="outlined" color="primary" onClick={onClick} >
            {children}
        </Button>
    )
}
GenericButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
}
export default GenericButton;