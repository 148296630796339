import * as React from "react";
// import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Layout from "../components/layouts/layout";
import GenericButton from "../components/shared/generic/button";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // fontSize: '2rem',
    },
    heading: {
        // fontSize: '2rem',
        // fontWeight: theme.typography.fontWeightRegular,
    },
    main: {
        // fontSize: '2rem',
    }
}));

const Test = () => {
    const classes = useStyles();

    return (
        <Layout>
            <div className={classes.main}>
                <div>Test page for material UI and theme updates</div>

                <GenericButton onClick={() => {
                    alert('clicked!')
                }}>explore efficacy</GenericButton>
                <SimpleAccordion className={classes.main}></SimpleAccordion>
            </div>
        </Layout>
    )
}

function SimpleAccordion() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Accordion 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Accordion 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className={classes.heading}>Disabled Accordion</Typography>
                </AccordionSummary>
            </Accordion>
        </div>
    );
}

export default Test;